import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/service.sass"

const Services = () => (
  <div className="service-component">
    <SEO title="services" />
    <h1 className="services-header">Our Services</h1>
    <div className="service-options-container">
        <div className="service-container red">
            <Link to="/services/electrical">
                <Image filename="house.png"/>
            </Link>
            <div className="service-info">
                <p>
                    Electrical
                </p>
                <Link to="/services/electrical">
                <button>
                        Read More
                </button>
                </Link>
            </div>
        </div>
        <div className="service-container blue">
            <Link to="/services/landscaping">
                <Image filename="landscapingicon.png"/>
            </Link>                
            <div className="service-info ">
                <p>
                    Landscaping
                </p>
                <Link to="/services/landscaping">
                <button>
                        Read More
                </button>
                </Link>
            </div>
        </div>
        <div className="service-container red">
            <Link to="/services/painting">
                <Image filename="paintingicon.png"/>
            </Link>
            <div className="service-info ">
                <p>
                    Painting
                </p>
                <Link to="/services/painting">
                <button>
                        Read More
                </button>
                </Link>
            </div>
            
        </div>
        <div className="service-container blue">
            <Link to="/services/seasonalandpermanent">
                <Image filename="seasonalicon.png"/>
            </Link>
            <div className="service-info ">
                <p>
                    Seasonal and Permanent
                </p>
                <Link to="/services/seasonalandpermanent">
                    <button>
                        Read More
                    </button>
                </Link>
            </div>
        </div>
        <div className="service-container red">
            <Link to="/services/sewerandplumbing">
                <Image filename="pipeicon.png"/>
            </Link>
            <div className="service-info ">
                <p>
                    Sewer and plumbing
                </p>
                <Link to="/services/sewerandplumbing">
                <button>
                        Read More
                </button>
                </Link>
            </div>
        </div>

        <div className="service-container blue">
            <Link to="/services/custompieces">
                <Image filename="parkicon.png"/>
            </Link>
            
            <div className="service-info ">
                <p>
                    Custom Pieces
                </p>
                <Link to="/services/custompieces">
                <button>
                        Read More
                </button>
                </Link>
            </div>
        </div>

        <div className="services-commercial">
            <h1>COMMERCIAL DRAIN AND SEWER CLEANING SERVICES</h1>
            <p>
                Commercial, industrial and municipal drains and sewers need servicing also. 
                Foreign objects and other belongings can cause blockages that require professional help.
                This includes but is not limited to commercial bathrooms, kitchens, floors, and anywhere else.
            </p>
            <Link to="/commercial">
                <button>Read More</button>      
            </Link>
        </div>
    </div>


  </div>
)

export default Services
